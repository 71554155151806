<template>
    <div>
        <h3>{{ $t('infoPage.infoHeader')}}!</h3>
        <p>{{ $t('infoPage.firstParagraph')}}</p>

        <p>{{ $t('infoPage.secondParagraph')}}</p>

        <p>{{ $t('infoPage.thirdParagraph')}} <a href="https://github.com/sebader/alpinehutproject" target="_blank">sebader/alpinehutproject</a></p>
    </div>
</template>

<script>
export default {
    async created() { },
    methods: {},
    computed: {},
    components: {}
};
</script>

<style>

</style>
